

table.table-presence {
  width: auto;

  th.day {
    width: 270px;
    text-align: center;
    font-weight: bold;
  }
}