h5 {
  margin-bottom: 0;
}

.clickable-row {
  cursor: pointer;
}

.file-box {
  width: 12.5%;
  font-size: smaller;
}