@use 'sass:color';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

// typo
h1 {
  font-size: 1.7rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.3rem;
}
.fs-7 {
  font-size: .8rem;
}

.text-muted {
  color: #aaa !important;
}

.cursor-pointer {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

// buttons
.btn {
  text-transform: uppercase;
  font-size: .7rem;
  padding: .75rem 1.5rem;
  letter-spacing: 1px;
  &.btn-sm {
    padding: .5rem .75rem;
  }
  &.btn-xs {
    padding: .1rem .25rem;
    font-size: .6rem;
  }
}
.btn-primary, .btn-primary:hover {
  color: #fff;
}
.input-group {
  .btn {
    padding: .5rem;
  }
}

a.disabled{
  pointer-events: none;
  cursor: default;
}

.bg-inter, .bg-intra, a.bg-inter, a.bg-intra {
  color: #fff !important;
  text-decoration: none;
}
.bg-inter, .bg-inter:active {
  background-color: #007cc0 !important;
  border: 1px solid color.adjust(#007cc0, $lightness: -3%);
  &.btn:hover {
    background-color: color.adjust(#007cc0, $lightness: -3%);
    border: 1px solid color.adjust(#007cc0, $lightness: -3%);
  }
}
.bg-intra, .bg-intra:active {
  background-color: #7d0096 !important;
  border: 1px solid color.adjust(#7d0096, $lightness: -3%);
  &.btn:hover {
    background-color: color.adjust(#7d0096, $lightness: -3%);
    border: 1px solid color.adjust(#7d0096, $lightness: -3%);
  }
}
.bg-waiting {
  background-color: var(--bs-warning);
}
.bg-option {
  background-color: #e73;
}
.bg-confirmed {
  background-color: #198754;
}
.bg-invoiced, .bg-done {
  background-color: #195487;
}

.bg-cancelled {
  background-color: #C00;
}

.auto-toggle {
  display: none;
}

.hover-shadow {
  transition: box-shadow .3s;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
  }
}

.table-actions {
  text-align: right;
  white-space: nowrap;
  vertical-align: middle;
  a:not(.dropdown-item) {
    color: $body-color;
    padding: .25rem;
    opacity: .5;
    &:hover {
      color: #111;
    }
  }
}

// tables
table.table {
  > :not(:first-child) {
    border-top: none;
  }
  td, th {
    // background-color: #fff;
    vertical-align: top;
    &:first-child {
      z-index: 10;
    }
  }
  th.th-sm {
    width: 144px;
  }
  th.th-lg {
    min-width: 415px;
  }
  td.label {
    width: 40%;
    font-size: .8rem;
    color: $gray;
  }
  tr:hover {
    .table-actions {
      a:not(.dropdown-item) {
        opacity: 1;
      }
    }
  }
  td.w-25 {
    width: 25%;
  }
  td.td-xs {
    width: 60px;
  }
  tr.tr-muted {
    td {
      color: color.adjust($body-color, $lightness: 20%);
    }
  }
  tr.bg-light {
    background-color: #f7f7f7;
    th, td {
      background-color: #f7f7f7;
    }
  }
  &.datatable {
    opacity: 0;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}

// cards
.card {
  border: none;
  box-shadow: 0 0 .875rem 0 rgba(41,48,66,.05);
  margin-bottom: 1.5rem;
  .card-header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border: 0;
  }
  h1 {
    font-size: 1.25rem;
    font-weight: bold;
  }
  h2 {
    font-size: 1.1rem;
    font-weight: bold; 
  }
  h3 {
    font-size: 1rem;
  }
  h1, h2, h3, h4, h5, h6 {
    color: #444;
  }
  .subcard {
    border: 1px solid rgba(41,48,66,.1);
    border-radius: .25rem;
    padding: 1rem;
    margin-bottom: 2rem;
  }
  .table-responsive.full-width {
    margin: 0 calc(-1 * var(--bs-card-spacer-x));
  
  }
}

form {
  label {
    font-size: .8rem;
    color: $gray;   
    &.required::after {
      content: '*';
      color: #C00;
      padding-left: 2px;
      vertical-align: top;
    }
  }
}

.horizontal-overflow {
  display:flex;
  // width:100 %;
  overflow: auto;
}

.hidden {
  display: none;
}

.empty-text {
  padding: 1rem 0;
  font-style: italic;
}

ul.nav-tabs {
  .nav-item .nav-link.active {
    background-color: #fff;
  }
}

.option-bullet {
  position: absolute;
  right: 2px;
  top: -2px;
  .arrow {
    color: white;
    font-size: 10px;
  }
}

.xdsoft_datetimepicker .xdsoft_calendar {
  td.xdsoft_today {
    font-weight: bold;
    &:not(.xdsoft_current) {
      color: #333;
    }
  }
}

input[name=daterange] {
  min-width: 200px !important;
}

.datepicker-group {
  cursor: pointer;
}
.xdsoft_datetimepicker .xdsoft_calendar tbody tr th:first-child {
  cursor: pointer;
  &::before {
    content: 'S'
  }
  &:hover {
    color: #fff !important;
    background: #ff8000 !important;
  }
}

.link-unset {
  all: unset;
  width: 100%;
  cursor: pointer;
}

.link-unset:hover {
  all:unset;
  width: 100%;
  cursor: pointer;
}

.fs-xs {
  font-size: 10px;
}

// multilines tooltips
.tooltip-inner {
  white-space:pre-wrap;
  text-align: left;
}
