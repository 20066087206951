

.tt {

  .answers-repeater {
    tr.answer:hover {
      .btn-outline-danger {
        display: block !important;
      }
    }
  }

  table {
    td.theme {
      font-size: 1rem;
      font-weight: bold;
    }
    td.subtheme {
      font-weight: bold;
    }
  }
}