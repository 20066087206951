
$sidebar-width: 70px;

div.wrapper {

  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  // sidebar
  nav.sidebar {
    width: $sidebar-width;
    background-color: $secondary;
    > ul {
      list-style: none;
      padding-left: 0;
      > li {
        position: relative;
        &.active {
          a i {
            color: #222;
          }
          background-color: #fff;
        }
        a {
          display: block;
          padding: 1rem;
          text-align: center;
          i {
            font-size: 1.1rem;
            color: #ccc;
          }
        }
        ul.submenu {
          list-style: none;
          display: none;
          position: absolute;
          z-index: 20;
          top: -1px;
          left: $sidebar-width + 10px;
          width: 260px;
          padding: .5rem 0;
          background-color: #fff;
          box-shadow: 3px 3px 10px 5px rgba(0,0,0,.1);
          &::before {
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            width: 10px;
            left: -10px;
          }
          li {
            a {
              color: #222;
              text-decoration: none;
              text-align: left;
              padding: .5rem 1rem;
              &:hover {
                color: $primary;
                background-color: #f6f6f6;
              }
            }
            hr {
              margin: .25rem 0;
            }
          }
        }
        &:hover {
          a {
            i {
              color: #222;
            }
            background-color: #fff;
          }
          ul.submenu {
            display: block;
          }
        }
      }
    }
  }

  // main
  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(100% - $sidebar-width);
    header {
      background-color: #fff;
      padding: 1rem 1.5rem;
      box-shadow: 0 0 2rem 0 rgba(41,48,66,.1);
      display: flex;
      justify-content: space-between;
      ol.breadcrumb {
        margin-bottom: 0;
      }
      nav {
        display: flex;
        justify-content: flex-end;
        div {
          margin-left: .5rem;
        }
      }
    }

    div.content {
      flex: 1;
      padding: 1rem 1.5rem;

      ol.breadcrumb {
        margin-bottom: 2.5rem;
      }
      nav.hmenu {
        margin-bottom: 1.5rem;
        .nav-link {
          &.active {
            border-bottom: 2px solid $primary;
          }
        }
      }
    }
    footer {
      padding: .5rem 1.5rem;
    }
  }
}

div.wrapper-full {
  main {
    height: 100vh;
    width: 100vw;
  }
}
