@use 'sass:color';

$left-width: 380px;
$day-width: 120px;
$title-width: 190px;

table:not(.dataTable) {
  th {
    position: relative;
    
    .table-sort-asc, .table-sort-desc {
      position: absolute;
      display: block;
      opacity: 0.125;
      right: 2px;
      line-height: 9px;
      font-size: 0.8em;
      cursor: pointer;
    }
    .table-sort-asc {
      bottom: 50%;
    }
    .table-sort-desc {
      top: 50%;
    }
    .active {
      opacity: 0.6;
    }
  }
}
.table-calendar {
  
  .table > :not(caption) > * > * {
    background-color: transparent;
  }

  table.table {
    font-size: .8rem;
    td, th {
      min-height: 40px;
      border-left: 1px solid #eee;
    }
    > thead > tr, > tbody > tr {
      border-right: 1px solid #999;
    }
    tr.line, th.line {
      border-bottom: 1px solid #999 !important;
    }
    th.left {
      min-width: $left-width;
      width: $left-width;
    }
    th.month {
      color: #fff;
      background-color: $month-color;
      text-align: center;
      text-transform: uppercase;
      &:nth-child(odd) {
        background-color: color.adjust($month-color, $lightness: 5%);
      }
    }
    th.week {
      background-color: $week-color;
      text-align: center;
      text-transform: capitalize;
      &:nth-child(odd) {
        background-color: color.adjust($week-color, $lightness: 5%);
      }
    }
    th.day {
      min-width: $day-width;
      width: $day-width;
      text-align: center;
      vertical-align: middle;
    }
    th.title, td.title {
      min-width: $title-width;
      width: $title-width;
    }
    td.day {
      text-align: center;
      vertical-align: middle;
    }
    td.monday, th.monday {
      border-left: 1px solid #999 !important;
    }
  }
  // border
  .b-top {
    border-top: 2px solid #333;;
  }
  .b-end {
    border-right: 2px solid #333;;
  }
  .b-bottom {
    border-bottom: 2px solid #333;;
  }
  .b-start {
    border-left: 2px solid #333;;
  }
}