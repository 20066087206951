

.reporting {
  table.table {
    th, td {
      vertical-align: middle;
      text-align: center;
    }
    .blue {
      background-color: #DDE7F3;
      font-weight: bold;
    }
    .w-16 {
      width: 16.6%;
    }
    .w-12 {
      width: 12.5%;
    }
  }
}