
.wrapper {

  .sidebar {
    width: 220px;
    height: 100%;
    padding: 1rem .5rem;
    a.nav-link {
      padding: .5rem;
      color: #333;
      &:hover:not(.active) {
        background-color: var(--bs-nav-pills-link-active-bg);
        color: #fff;
      }
      &.active {
        color: #fff;
      }
    }
  }

  main {
    padding: 1rem;
    // background-color: #E4EAEE;
  }

}
