$cell-width: 65px;
$cell-height: $line-height-base * 2em; // For line-height 

.sessions-calendar {
  padding-bottom: 200px;
  table.table {
    width: auto;
    table-layout: fixed;
    background-color: #fff;
    th {
      height: $cell-height;
    }
    td {
      width: $cell-width;
      min-width: $cell-width;
      max-width: $cell-width;
      height: $cell-height;
      padding: 0;
      .session-cell {
        position: relative;
        @for $i from 1 through 15 {
          $size: $cell-width * $i;
          .session-size-#{$i} { 
            position: absolute;
            padding: 2px 3px;
            width: $size;
            height: $cell-height;
          }
        }
        .has-popover {
          height: 100%;
        }
        .details-popover {
          position: absolute;
          z-index: 10;
          display: none;
          top: -20px;
          min-height: 60px;
          right: -335px;
          width: 330px;
          text-align: left;
          &::after {
            content: "";
            position: absolute;
            height: 100%;
            top: 0;
            width: 4px;
            left: -4px;
          }
          .progress {
            height: 10px;
            border-radius: 10px
          }

          .progress div {
            background-color: $primary;
          }

          .text1 {
            font-size: 12px;
            font-weight: 600;
          }
          .text2 {
            color: #a5aec0
          }
          .text3 {
            font-size: 14px;
            font-weight: 800;
            color: orange;
          }
        }
        &.hover {
          background-color: #fafafa;
        }    
        .btn {
          height: 100%;
          padding: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
  }
}