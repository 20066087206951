
// select2
.select2-container--default {
  width: 100% !important;
  min-width: 179px;

  .select2-selection--multiple, .select2-selection--single {
    border: 1px solid #ced4da;
    background-color: #f7f9fc;
    min-height: 34px;

    textarea.select2-search__field {
      margin-top: 8px;
      height: 18px;
    }  
  }
  .select2-selection--single {
    padding: 2px 6px;
    .select2-selection__rendered {
      color: #777;
    }
  }
  &.select2-container--focus .select2-selection--multiple,
  &.select2-container--focus .select2-selection--single {
    border: 1px solid #9fc0f5;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(63, 128, 234, 0.25);
  }
}

.select2.is-invalid + .select2-container--default {
  .select2-selection--multiple, .select2-selection--single {
    border-color: #dc3545;
  }

}
  