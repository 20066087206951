@use 'sass:color';

$bordercolor: rgb(222, 226, 230);


// session structure form
.session-table {
  display: flex;
  // font-size: .8rem;
  .labels-col {
    border-top: 1px solid $bordercolor;
    border-left: 1px solid $bordercolor;
    width: 100px;
    font-size: .8rem;
  }
  .session-day {
    border-top: 1px solid $bordercolor;
    width: 240px;
  }
  .cell {
    padding: .5rem;
    height: 2.2rem;
    border-bottom: 1px solid $bordercolor;
    border-right: 1px solid $bordercolor;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .cell-split {
    display: flex;
    justify-content: center;
  }
  .cell-50 {
    width: 50%;
  }
  .cell-33 {
    width: 33.33%;
  }
  .cell-66 {
    width: 66.66%;
  }
  .cell-100 {
    width: 100%;
  }
  .cell-sm {
    font-size: .8rem;
  }
  .cell-bold {
    font-weight: bold;
  }
  .b-right {
    border-right: 1px solid $bordercolor;
  }
  .bg-t {
    background-color: #E2EFD9;
  }
  input {
    padding: 0;
    // margin: 0 8px;
    border: none;
    text-align: center;
    background-color: #f8f8f8;
    &.input-nb {
      max-width: 40px;
    }
  }

}

// session show
.session-calendar {
  table.table {
    width: auto;
  }
  font-size: .8rem;
  .day {
    width: 130px;
  }
}

// sessions planning
.session-planning {
  table.table {
    width: auto;
  }
  td .session-categories .badge {
    cursor: pointer;
  }
}

.bg-finished {
  color: #fff !important;
  text-decoration: none;
}

.bg-finished {
  background-color: #4d008c;
  border: 1px solid color.adjust(#4d008c, $lightness: -3%);
  &.btn:hover {
    background-color: color.adjust(#4d008c, $lightness: -3%);
    border: 1px solid color.adjust(#4d008c, $lightness: -3%);
  }
}

table.sessions-index {

  .details-popover {
    display: none;
    position: absolute;
    z-index: 10;
    margin-top: .25rem;
    width: 400px;
  }
  tr:hover {
    .details-popover {
      display: block;
    }
  }

}

.responsive-overflow { 
  max-height: 13rem;
  overflow-x: auto;
  overflow-y: scroll;
  .dropdown-item { 
    height: auto;
  }
}