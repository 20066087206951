

.hover-dropdown {
  &:hover {
    .fa-chevron-right {
      transform: rotate(90deg);
    }
    .dropdown-menu {
      display: block;
    }
  }
}

table.table {
  td.label {
    color: #999;
  }
}

.inactive-state { 
  font-size: 0.75rem;
  font-style: italic;
  margin: 0;
  color: grey;
}

ul.nav-underline {
  a.nav-link:not(.active) {
    color: #999;
    border-bottom: 2px solid #999;
  }
}