
.instructors-calendar {
  padding-bottom: 6rem;
  .badge {
    padding: 0.55em 0.45em;
    border: 1px;
  }
  table.table {
    th.day {
      width: 140px;
      min-width: 140px;
      overflow: hidden;
    }
  }
  td.day {
    position: relative;
    cursor: pointer;
    color: #333;
    > span {
      font-size: .6rem;
      line-height: .7rem;
      font-weight: bold;  
      text-transform: uppercase;
    }
    .badge-option {
      position: absolute;
      right: .25rem;
      top: .25rem;
      font-size: .75rem;
    }
    .assign-popover {
      position: absolute;
      z-index: 10;
      display: none;
      top: -20px;
      min-height: 60px;
      right: 139px;
      width: 220px;
      background-color: #f4f4f4 !important;
      color: $body-color;
      padding: .5rem;
      text-align: left;
      border: 1px solid rgba(41,48,66,.2);
      .btn-assign-instructor {
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
      table.table-sm {
        background-color: #fff;
        margin-bottom: .5rem;
      }
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        width: 4px;
        left: -4px;
      }
    }
    &:hover {
      &::after {
        outline: 1px solid rgba(41,48,66,.2);
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        top: 0;
        left: 0;
      }
    }
  }
}

.borderless-table {
  td, th {
    border:none;
  }
}

#assign-modal {
  .btn-check:checked + .btn-re {
    background-color: #777;
  }
  .btn-check:checked + .btn-dispo {
    background-color: #9ce39c;
    color: #0e6618;
  }
}

#assign-form {
  .btn-group > .btn-sm {
    padding: .5rem .25rem;
  }
}

table.instructors-assign {
  .btn-check:not(:checked) + .btn {
    &:hover {
      background-color: #ddd;
    }
  }
  .btn-group > .btn-sm {
    padding: .5rem .75rem;
    min-width: 44px;
  }
}